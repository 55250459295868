<template>
  <div class="main-page-menu">
    <router-link :to="{name: 'main-page'}" class="mobile-logo">
      <img :src="$getAsset('/assets/mcr_logo_dude_transparent.png')" />
    </router-link>
    <router-link :to="{name: 'main-page'}" class="logo">
      <img :src="$getAsset('/assets/mcr_logo_white_transparent.png')" />
    </router-link>

    <div class="menu-items">
      <main-menu-item
        v-for="(item, index) of menuItems"
        :key="index"
        :text="item.text"
        :mobile-text="item.mobileText"
        :mobile-se-text="item.mobileSeText"
        :sub-menu="item.subMenu"
        :route="item.route"
        :is-mobile-view="isMobileView"
        :click-handler="item.clickHandler"
        :class="item.classes"
      ></main-menu-item>
      <main-menu-item
        :text="loginText"
        class="login-link"
        rel="nofollow"
        :route="loginRoute"
        :is-mobile-view="isMobileView"
        :click-handler="onLoginClick"
      ></main-menu-item>
    </div>
  </div>
</template>

<script>
import MainMenuItem from '@common/elements/main-menu/main.menu.item';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageIdentifier, getRoutePageName} from '@common/utils/analytics/utils.analytics';
import {getFamilyTreeRoute} from '@common/utils/utils.routes';

export default {
  computed: {
    isMobileView() {
      return this.$store.getters.windowWidthState <= this.$breakpoints.mainMenu;
    },
    menuItems() {
      let items = [{text: 'Search', route: {name: 'platform-main'}}];
      if (!this.isMobileView) {
        items.push({
          text: 'Family Tree',
          mobileSeText: 'Tree',
          route: getFamilyTreeRoute(this.$store),
        });
      }
      return [
        ...items,
        {text: 'Services', route: {name: 'services-main'}},
        {text: 'Plans', route: {name: 'subscription-plans'}},
      ];
    },
    loginRouteQuery() {
      const route = {name: 'platform-main'};
      const redirect = this.$router.resolve(route).href;
      return {redirect};
    },
    loginRoute() {
      if (this.$store.getters.userIsLoggedInState) {
        return {name: 'platform-main'};
      }
      return {name: 'login', query: this.loginRouteQuery};
    },
    loginText() {
      return 'Log in';
    },
  },
  methods: {
    trackClick(label) {
      AnalyticsMainHandler.trackClickMenuItem(
        label,
        getRoutePageName(this.$route),
        getRoutePageIdentifier(this.$route)
      );
    },
    onLoginClick() {
      this.trackClick(this.loginText);
    },
  },
  components: {MainMenuItem},
  name: 'MainPageMenu',
};
</script>

<style lang="scss" scoped>
.main-page-menu {
  position: absolute;
  width: 99%;
  margin-left: 0;
  top: 10px;
  left: 0;
  z-index: $main-menu-z-index;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .logo {
    margin-left: 20px;
    display: block;
    margin-right: 20px;

    -webkit-filter: drop-shadow(1px 1px 1px rgba(black, 0.9));
    filter: drop-shadow(1px 1px 1px rgba(black, 0.9));

    img {
      width: 220px;
      height: 73px;
    }
  }
  .mobile-logo {
    display: none;
    align-items: center;
    justify-content: center;
    padding-left: 16px;
    padding-right: 9px;
    height: $main-menu-height-mobile;
    flex-shrink: 0;
    img {
      width: 16px;
      height: 28px;
    }
  }

  .menu-items {
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;
    .main_menu_item {
      margin-left: 20px;
    }

    .main_menu_item.login-link {
      margin-left: auto;
      white-space: nowrap;
      height: 100%;
      display: flex;
    }
  }
}

@media only screen and (min-width: $main-menu-breakpoint) {
  $items-offset-top: 10px;
  $items-shadow: 1px 1px 2px rgba(black, 0.9);
  .main-page-menu {
    .menu-items {
      margin-top: $items-offset-top;

      .main_menu_item::v-deep .main_menu_link {
        text-shadow: $items-shadow;
        color: $main-menu-alt-color;
      }
    }

    .login-link {
      opacity: 0.7;
      &::v-deep .main_menu_link {
        text-shadow: $items-shadow;
      }
    }
  }
}
@media only screen and (max-width: $main-menu-breakpoint) {
  .main-page-menu {
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $background-contrast-color;

    .mobile-logo {
      display: flex;
    }
    .logo {
      display: none;
    }

    .menu-items {
      align-items: center;

      background-color: $background-contrast-color;
      width: 100%;
      .main_menu_item {
        padding: 0 12px;
        height: $main-menu-height-mobile;
        line-height: $main-menu-height-mobile;
        margin-left: 0;
      }

      .login-link {
        padding: 0 13px 0 0;
        &::v-deep .main_menu_link {
          color: $mcr-light-tan;
        }
      }
    }
  }
}
@media only screen and (max-width: $breakpoint-mobile-se) {
  .main-page-menu {
    .mobile-logo {
      padding-right: 6px;
    }
    .menu-items {
      .main_menu_item {
        padding: 0 9px;
      }
    }
  }
}

@media only screen and (max-width: 350px) {
  .main-page-menu {
    .menu-items {
      .main_menu_item {
        padding: 0 5px;
      }
    }
  }
}
</style>
